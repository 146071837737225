import FormValidator from "../FormValidator"

export const validator = new FormValidator([
  {
    field: "name",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre prénom et nom",
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre e-mail",
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Cet e-mail n'est pas valide",
  },
  {
    field: "phoneNumber",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre numéro de téléphone",
  },
  {
    field: "phoneNumber",
    method: "isMobilePhone",
    args: ["any"], // args is an optional array of arguements that will be passed to the validation method
    validWhen: true,
    message: "Ce numéro n'est pas valide",
  },
  {
    field: "message",
    method: "isEmpty",
    validWhen: false,
    message: "Votre message est vide",
  },
])
