import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  background: ${props => props.theme.secondaryDarkPurple};
  display: flex;
  justify-content: center;
  width: 100%;
`

const WrapperBreadcrumb = styled.div`
  background: ${props => props.theme.secondaryDarkPurple};
  display: none;
  margin-top: ${globalVariables.navbarHeightMobile};
  @media (min-width: ${globalVariables.burgerBreakpoint}) {
    margin-top: ${globalVariables.navbarHeight};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 50px;
    height: 50px;
  }
  a:last-child {
    color: ${props => props.theme.lightBlue};
  }
`

const LinkStyled = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0;
  color: white;
  text-decoration: none;
  position: relative;
  padding: 0 0.4rem 0 0;
  &:not(:first-child) {
    padding: 0 0.4rem;
  }

  &:visited,
  &:-webkit-any-link {
    color: white;
  }
  &:not(:last-child)::after {
    content: ">";
    position: absolute;
    top: 50%;
    right: -3px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateY(-50%);
  }
`

const Text = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0;
  color: white;
  text-decoration: none;
  position: relative;
  padding: 0 0.4rem;
  &::after {
    content: ">";
    position: absolute;
    top: 50%;
    right: -3px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateY(-50%);
  }
`

const BreadCrumbv2 = ({
  location,
  crumbLabel,
  locationBis,
  crumbLabelBis,
  locationTer,
  crumbLabelTer,
  noLocation,
}) => {
  return (
    <Wrapper>
      <WrapperBreadcrumb>
        <LinkStyled to="/">Accueil</LinkStyled>
        {noLocation ? (
          <Text>{crumbLabel}</Text>
        ) : (
          <LinkStyled to={location}>{crumbLabel}</LinkStyled>
        )}
        {crumbLabelBis && (
          <LinkStyled to={locationBis}>{crumbLabelBis}</LinkStyled>
        )}
        {crumbLabelTer && (
          <LinkStyled to={locationTer}>{crumbLabelTer}</LinkStyled>
        )}
      </WrapperBreadcrumb>
    </Wrapper>
  )
}

BreadCrumbv2.propTypes = {
  location: PropTypes.string,
  crumbLabel: PropTypes.string,
  locationBis: PropTypes.string,
  locationTer: PropTypes.string,
  crumbLabelBis: PropTypes.string,
  crumbLabelTer: PropTypes.string,
  noLocation: PropTypes.bool,
}

export default BreadCrumbv2
