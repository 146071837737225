/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import DeviceProvider from "./elements/DeviceProvider"
import theme from "./theme"
import Navbar from "./Navbar"
import "./layout.css"
import StickyMobile from "./elements/StickyMobile"
import config from "react-reveal/globals"
import Footerv2 from "./Footerv2"
import BreadCrumbv2 from "./elements/Breadcrumb/Breadcrumbv2"

config({ ssrFadeout: true })

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  left: ${props => (props.isBurgerActive ? "-240px" : "0")};
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  .dropdown-menu-enter-done {
    transform: translateY(0%);
    opacity: 1;
  }

  .dropdown-menu-exit-active {
    transform: translateY(-100%);
    opacity: 0;
  }
  .dropdown-enter-done {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }

  .dropdown-exit-active {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-2em);
  }
`

const Layout = ({
  location,
  crumbLabel,
  locationBis,
  locationTer,
  crumbLabelBis,
  crumbLabelTer,
  locationGTM,
  noLocation,
  children,
}) => {
  useEffect(() => {
    // si l'url ne contient pas blog alors on supprime les elements de filtre et de pagination du localstorage
    if (locationGTM && locationGTM.indexOf("blog") === -1) {
      let keysToRemove = ["page", "theme", "level", "type", "search"]

      keysToRemove.forEach(k => localStorage.removeItem(k))
    }
  }, [locationGTM]) // Only re-run if value changes

  const [isBurgerActive, setToggleMenu] = useState(false)

  const handleToggleMenu = () => {
    setToggleMenu(!isBurgerActive)
  }

  const data = useStaticQuery(
    graphql`
      query {
        contact: file(relativePath: { eq: "icons/sticky.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 60, height: 60) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <ThemeProvider theme={theme}>
      <DeviceProvider>
        <Wrapper isBurgerActive={isBurgerActive}>
          <StickyMobile image={data.contact.childImageSharp.fixed} />
          <Navbar
            handleToggleMenu={handleToggleMenu}
            isBurgerActive={isBurgerActive}
          />
          {location && location !== "/" && (
            <BreadCrumbv2
              location={location}
              crumbLabel={crumbLabel}
              locationBis={locationBis}
              crumbLabelBis={crumbLabelBis}
              locationTer={locationTer}
              crumbLabelTer={crumbLabelTer}
              noLocation={noLocation}
            />
          )}

          {children}
          <Footerv2 locationGTM={locationGTM} />
        </Wrapper>
      </DeviceProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noLocation: PropTypes.bool,
  location: PropTypes.string,
  crumbLabel: PropTypes.string,
  locationBis: PropTypes.string,
  locationTer: PropTypes.string,
  crumbLabelBis: PropTypes.string,
  crumbLabelTer: PropTypes.string,
  locationGTM: PropTypes.string,
}

export default Layout
