import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

const LinkStyled = styled(Link)`
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 60px;
  height: 60px;
  z-index: 3;
  @media (min-width: ${globalVariables.minTablet}) {
    display: none;
  }
`

const StickyMobile = ({ image }) => {
  return (
    <LinkStyled to="/contact">
      <Img fixed={image} />
    </LinkStyled>
  )
}

export default StickyMobile
