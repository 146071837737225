import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import globalVariables from "../../globalVariables"
import Section from "../../elements/Section"
import Title from "../../elements/Title"
import ContactForm from "../../elements/ContactForm"
import ImageMain from "../../../images/contact/contact-main.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minDesktop}) {
    flex-direction: row;
    height: 595px;
  }
`
const Intro = styled.div`
  display: none;
  position: relative;
  z-index: 1;
  background-image: url(${ImageMain});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  @media (min-width: ${globalVariables.medDesktop}) {
    display: block;
    flex: 1 0 515px;
  }
`

const WrapperContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 50px 50px 10px 70px;
  }
`

const Text = styled.div`
  p {
    color: white;
    text-align: justify;
  }

  @media (min-width: ${globalVariables.medDesktop}) {
    margin: 25px 0 35px;
  }
`

const Contact = ({ title, text, id, location }) => {
  return (
    <Section padding="0" color="greyLighter" border id={id}>
      <Container>
        <Intro>
          <WrapperContent>
            <Title
              color="white"
              className="title1"
              traitWidth="300px"
              traitLeft="-75px"
            >
              {title}
            </Title>
            <Text>{Parser(text)}</Text>
          </WrapperContent>
        </Intro>
        <ContactForm location={location} />
      </Container>
    </Section>
  )
}

export default Contact
