import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBloc from "../components/elements/ContentBloc"
import Contact from "../components/Home/Contact"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const bannerLeftCardContent = {
  titleSecondLine: "Mentions légales",
  subTitle: "Informations",
}

const MentionsLegales = ({ data, location }) => {
  const { mentionsLegales, bloc_contact } = data

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Mentions Légales"
      locationGTM={location.href}
    >
      <SEO
        title={mentionsLegales.acf.title}
        description={mentionsLegales.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        hasBreadCrumb
        bannerLeftCardContent={bannerLeftCardContent}
        isMentionsLegalesPage
      />
      <ContentBloc contenu={mentionsLegales.acf.contenu} />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default MentionsLegales

export const query = graphql`
  query {
    mentionsLegales: wordpressPage(
      id: { eq: "f59d5fd1-b822-57f4-bc77-2d89ddc09804" }
    ) {
      acf {
        title
        meta_description
        titre_banniere
        texte_banniere
        image_banniere {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        contenu
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
