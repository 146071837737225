import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

import bannerPurpleAgence from "../../../images/banner_home_agence.png"

const CardAgence = styled.div`
  height: 359px;
  width: 359px;

  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    display: none;
  }
`

const ImageAgence = styled.img``

const RightCardArticle = () => {
  return (
    <>
      <CardAgence>
        <ImageAgence src={bannerPurpleAgence} />
      </CardAgence>
    </>
  )
}

export default RightCardArticle
