import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import globalVariables from "../../globalVariables"

const Section = styled.section`
  z-index: 1;
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 2rem;
  }
`

const Container = styled.div`
  max-width: 1095px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: -${globalVariables.marginTopContainer};
  }
`

const Card = styled.div`
  flex: ${props => (props.flex ? props.flex : null)};
  background: ${props =>
    props.color ? props.theme[props.color] : props.theme.greyLighter};
  margin: 0 0 20px;
  padding: ${props => (props.padding ? "30px 10px" : "0")};

  @media (max-width: ${globalVariables.maxMobile}) {
    &.lesplus {
      margin: 0;
      border-bottom: 1px solid white;
    }
    &.contact-card {
      text-align: center;
      a {
        min-width: 220px;
      }
      p {
        margin-bottom: 2rem;
      }
    }
    &.button-contact {
      padding: 30px 10px;
      height: 143px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "0")};
    margin: 10px;
  }
`

const ContentBody = styled.div`
  h2 {
    color: ${props => props.theme.primary};
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
`

const ContentBloc = ({ contenu }) => {
  return (
    <Section>
      <Container>
        <Card padding="65px 35px 65px 45px">
          <ContentBody>{Parser(contenu)}</ContentBody>
        </Card>
      </Container>
    </Section>
  )
}

export default ContentBloc
