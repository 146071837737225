import React, { useState } from "react"
import styled from "styled-components"

import Title from "../Title"
import Input from "../Input"
import Textarea from "../Textarea"
import Button from "../Button"
import globalVariables from "../../globalVariables"
import { validator } from "./validationRules"

const Wrapper = styled.div`
  padding: 50px 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "50px 70px")};
  }
`

const Text = styled.p`
  margin-bottom: 0;
  padding-top: 2rem;
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  position: relative;
  padding-top: 35px;
`

const Spacer = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  padding: 10px;
  position: relative;
  button {
    margin-top: 10px;
  }
  text-align: ${props => (props.textAlign ? "center" : null)};
`
const ValidationMessage = styled.span`
  position: absolute;
  bottom: 11px;
  left: 32px;
  font-size: 12px;
  color: ${props => props.theme.error};
  opacity: ${props => (props.isInvalid ? "1" : "0")};
`

const SuccessMessage = styled.span`
  position: absolute;
  bottom: -25px;
  font-size: 90%;
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.white};
  opacity: ${props => (props.isSent ? "1" : "0")};
  padding: 2px 5px;
`

const ContactForm = ({ id, text, padding, location }) => {
  const initialState = {
    location,
    name: "",
    email: "",
    phoneNumber: "",
    siteWeb: "",
    message: "",
    isSent: false,
    validation: validator.valid(),
  }
  const [formData, setFormData] = useState({
    ...initialState,
  })

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handleInputChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    // const { name, email, phoneNumber, siteWeb, message } = formData

    const validation = validator.validate(formData)
    setFormData({
      ...formData,
      validation,
    })
    setIsFormSubmitted(true)

    if (validation.isValid) {
      const encode = data => {
        return Object.keys(data)
          .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&")
      }
      fetch(event.target.action, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formData }),
      })
        .then(() => {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "contactForm",
              eventCategory: "Formulaire de contact",
              eventAction: "Validation formulaire de contact",
              eventLabel: location,
            })
          } else {
            window.dataLayer = []
            window.dataLayer.push({
              event: "contactForm",
              eventCategory: "Formulaire de contact",
              eventAction: "Validation formulaire de contact",
              eventLabel: location,
            })
          }
          setFormData({ ...initialState, isSent: true })
        })
        .catch(error => alert(error))
    }
  }

  const { name, email, phoneNumber, siteWeb, message, validation } = formData
  // if the form has been submitted at least once, then check validity every time we render
  // otherwise just use what's in state
  const copyData = { ...formData }
  const formValidation =
    isFormSubmitted && !formData.isSent
      ? validator.validate(copyData)
      : validation

  return (
    <Wrapper id={id} padding={padding}>
      <Title className="title1 trait">Contactez-nous</Title>
      {text && <Text>{text}</Text>}
      <FormStyled
        onSubmit={handleSubmit}
        data-netlify="true"
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
      >
        <Spacer flex="1 1 50%">
          <input type="hidden" name="form-name" value="contact" />
          <input
            type="text"
            value={location}
            name="location"
            id="location"
            style={{ display: "none" }}
            readOnly
          />
          <Input
            type="text"
            placeholder="Prénom Nom"
            value={name}
            handleChange={e => handleInputChange(e)}
            name="name"
            required
            fullWidth
            id="name"
          />
          <ValidationMessage isInvalid={formValidation.name.isInvalid}>
            {formValidation.name.message}
          </ValidationMessage>
        </Spacer>
        <Spacer flex="1 0 50%">
          <Input
            type="email"
            placeholder="E-mail"
            value={email}
            handleChange={e => handleInputChange(e)}
            name="email"
            required
            fullWidth
            id="email"
          />
          <ValidationMessage isInvalid={formValidation.email.isInvalid}>
            {formValidation.email.message}
          </ValidationMessage>
        </Spacer>
        <Spacer flex="1 0 50%">
          <Input
            type="tel"
            placeholder="Téléphone"
            value={phoneNumber}
            handleChange={e => handleInputChange(e)}
            name="phoneNumber"
            fullWidth
            id="phoneNumber"
          />
          <ValidationMessage isInvalid={formValidation.phoneNumber.isInvalid}>
            {formValidation.phoneNumber.message}
          </ValidationMessage>
        </Spacer>
        <Spacer flex="1 1 50%">
          <Input
            type="text"
            placeholder="Site internet"
            value={siteWeb}
            handleChange={e => handleInputChange(e)}
            name="siteWeb"
            required
            fullWidth
            id="siteWeb"
          />
        </Spacer>
        <Spacer>
          <Textarea
            type="text"
            placeholder="Votre message..."
            value={message}
            handleChange={e => handleInputChange(e)}
            name="message"
            required
            fullWidth
            id="message"
          />
          <ValidationMessage isInvalid={formValidation.message.isInvalid}>
            {formValidation.message.message}
          </ValidationMessage>
        </Spacer>
        <SuccessMessage isSent={formData.isSent}>
          Merci, votre message a bien été envoyé !
        </SuccessMessage>
        <Spacer textAlign="center">
          <Button title="Envoyer" type="submit" colors="primaryWithBg" />
        </Spacer>
      </FormStyled>
    </Wrapper>
  )
}

export default ContactForm
